<template>
        <div class="good-list" v-loading="loading">
                <div class="mb size-lg">
                        <div class="max-width">
                                {{keyword==''?'全部商品':'搜索“'+keyword+'”的结果'}}
                        </div>
                </div>
                <a-affix @change="affixChange">
                <el-card :body-style="{padding:'10px 20px'}" :shadow="affixStatus?'always':'never'" class="no-border no-radius">
                        <div class="max-width">
                                <el-row :gutter="10">
                                        <el-col :span="24">
                                                <template v-for="(sort,index) in sorts">
                                                        <el-link :key="index" :underline="false" :type="sort.field == filter.sort.field?'primary':'info'" @click="onsort(sort)">
                                                                {{sort.label}}
                                                                <template v-if="sort.sort">
                                                                        <i class="el-icon-sort" v-if="filter.sort.field != sort.field"></i>
                                                                        <i class="el-icon-sort-down" v-if="filter.sort.field == sort.field && filter.sort.order == 'desc'"></i>
                                                                        <i class="el-icon-sort-up" v-if="filter.sort.field == sort.field && filter.sort.order == 'asc'"></i>
                                                                </template>
                                                        </el-link>
                                                        <el-divider v-if="index<sorts.length-1" :key="'divider-'+index" direction="vertical"></el-divider>
                                                </template>
                                        </el-col>
                                </el-row>
                        </div>
                </el-card>
                </a-affix>
                <el-card shadow="never" class="max-width no-border no-radius mt no-bg" :body-style="{padding:0}">
                        <el-row :gutter="10" v-if="list.length>0">
                                <el-col :lg="6" :md="6" :sm="8" :xs="12" v-for="(good,index) in list" :key="index">
                                        <good-item-square :item="good" @click="clickHandler" @factory-click="clickFactoryHandler"></good-item-square>
                                </el-col>
                        </el-row>
                        <div v-else class="text-center pd-y-lg">
                                <a-empty :image="empty_image" description="Oh～ 谢特，竟然没有数据"/>
                        </div>
                </el-card>
                <el-card shadow="never" class="text-center no-border no-radius mt">
                        <div class="max-width">
                                <el-pagination background :page-count="page_count" :current-page.sync="filter.page" @current-change="loadList"></el-pagination>
                        </div>
                </el-card>
        </div>
</template>
<script>
import GoodItemSquare from '@/components/goods/good-item-square.vue'
import {Empty} from 'ant-design-vue'
export default {
        components:{
                GoodItemSquare
        },
        props:{
                keyword:String
        },
        data() {
                return {
                        empty_image:Empty.PRESENTED_IMAGE_SIMPLE,
                        //排序s
                        affixStatus:false,
                        sorts:[
                                {
                                        field:'',
                                        label:'综合排序',
                                        sort:false
                                },
                                {
                                        field:'popularity',
                                        label:'人气排序',
                                        sort:false
                                },
                                {
                                        field:'volume',
                                        label:'销量排序',
                                        sort:false
                                },
                                {
                                        field:'price',
                                        label:'价格',
                                        sort:true
                                }
                        ],

                        page_count:0,
                        //筛选过滤
                        filter:{
                                keyword:'',
                                page:1,
                                cate:'',
                                sort:{
                                        field:'',
                                        order:'desc'
                                },
                        },

                        //商品列表
                        list:[
                                
                        ],
                        loading:false,
                }
        },
        created() {
                this.filter.keyword = this.keyword;
                this.filter.page = 1;
                this.loadList()
        },
        methods:{
                //商品item的厂家信息处理器
                clickFactoryHandler() {
                        this.$message.info('跳转-商家详情')
                },
                //商品点击处理器
                clickHandler() {
                        this.$message.info('跳转-商品详情')
                },
                affixChange( status ) {
                        this.affixStatus = status;
                },
                onsort( sort ) {
                        if(this.loading) return;
                        let old_field = this.filter.sort.field;
                        let field = sort.field;
                        let order = sort.sort == true ? (old_field==field ? (this.filter.sort.order=='asc'?'desc':'asc') : 'desc') : 'desc';
                        this.filter.sort.order = order;
                        this.filter.sort.field = field;
                        this.loadList();
                },
                async loadList() {
                        this.list = [];
                        this.loading = true;
                        let data = await this.$api.good_list(this.filter);
                        this.loading = false;
                        if(data) {
                                this.list.push(...data.list);
                                this.filter.page = data.page;
                                this.page_count = data.page_count;
                        }
                }
        }
}
</script>